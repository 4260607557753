import Vue from 'vue';
import i18n from '../i18n';
import store from '../store';
import VueRouter from 'vue-router';
import ActivateView from '../views/activate/ActivateView.vue';
import BrandView from '../views/brand/BrandView.vue';
import BrandsView from '../views/brands/BrandsView.vue';
import BudgetView from '../views/budget/BudgetView.vue';
import CampaignsView from '../views/campaigns/CampaignsView.vue';
import OfferDashboardView from '../views/dashboard/OfferDashboardView.vue';
import IntegrationView from '../views/integration/IntegrationView.vue';
import LoginView from '../views/login/LoginView.vue';
import OfferView from '../views/offer/OfferView.vue';
import OffersView from '../views/offers/OffersView.vue';
import PanelsView from '../views/panels/PanelsView.vue';
import RecoverPasswordView from '../views/recover-password/RecoverPasswordView.vue';
import ResetPasswordView from '../views/reset-password/ResetPasswordView.vue';
import SettingsView from '../views/settings/SettingsView.vue';
import SignupView from '../views/signup/SignupView.vue';
import SuccessView from '../views/success/SuccessView.vue';
import RedirectView from '../views/signup/RedirectView.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/signup-admin', name: 'Signup Admin', component: SignupView, meta: { title: 'Sign up • Modatta' } },
  { path: '/signup', name: 'Signup', component: RedirectView, meta: { title: 'Sign up • Modatta' } },
  {
    path: '/activate',
    name: 'Activate',
    component: ActivateView,
    meta: { title: i18n.t('signup.title') + ' • Modatta' },
  },
  { path: '/login', name: 'Login', component: LoginView, meta: { title: 'Log in • Modatta' } },
  {
    path: '/success',
    name: 'Success',
    component: SuccessView,
    meta: { title: i18n.t('signup.success.title_short') + ' • Modatta' },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordView,
    meta: { title: 'Reset Password • Modatta' },
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPasswordView,
    meta: { title: 'Recover Password • Modatta' },
  },
  { path: '/', beforeEnter: (to, from, next) => next({ name: 'Dashboard' }) },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: OfferDashboardView,
    meta: { title: 'Dashboard • Modatta', requiresAuth: true },
  },
  { path: '/brands', name: 'Brands', component: BrandsView, meta: { title: 'Brands • Modatta', requiresAuth: true } },
  { path: '/brands/:id', name: 'Brand', component: BrandView, meta: { title: 'Brand • Modatta', requiresAuth: true } },
  {
    path: '/offers',
    name: 'Offers',
    component: CampaignsView,
    meta: { title: i18n.t('brands-dashboard.offers') + ' • Modatta', requiresAuth: true },
  },
  {
    path: '/offers/new-offer',
    name: 'NewOffer',
    component: OfferView,
    meta: { title: 'New Offer • Modatta', requiresAuth: true },
  },
  { path: '/offers/:id', name: 'Offer', component: OffersView, meta: { title: 'Offer • Modatta', requiresAuth: true } },
  { path: '/budget', name: 'Budget', component: BudgetView, meta: { title: 'Budget • Modatta', requiresAuth: true } },
  /*{
    path: '/integration',
    name: 'Integration',
    component: IntegrationView,
    meta: { title: 'Integration • Modatta', requiresAuth: true },
  },*/
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView,
    meta: { title: 'Settings • Modatta', requiresAuth: true },
  },
  {
    path: '/panels',
    name: 'Panels',
    component: PanelsView,
    meta: { title: 'Panels • Modatta', requiresAuth: true },
  },
  {
    path: '/offer-dashboard/:id',
    name: 'Offer Dashboard',
    component: OfferDashboardView,
    meta: { title: 'Dashboard da Missao • Modatta', requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.user.token == null) {
      next({ name: 'Login' });
      return;
    } else {
      next();
    }
  } else {
    if (store.state.user.token != null) {
      next({ name: 'Dashboard' });
      return;
    } else {
      next();
    }
  }
});

export default router;
