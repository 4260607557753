<template>
  <div></div>
</template>

<script>
export default {
  name: 'RedirectComponent',
  mounted() {
    // Redirect to the desired URL when the component is mounted
    window.location.href = 'https://survey.modatta.com/bem-vindo-a-modatta';
  },
};
</script>
